<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header">
              Producto
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-dark badge-pill float-right sdw">
                    <i class="fas fa-box text-white"></i>
                  </span>
                </template>
                <span>Detalle Producto</span>
              </v-tooltip>
                            <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-danger badge-pill pointer float-right sdw mr-3"
                    @click="eliminate()"
                  >
                    <i class="fas fa-trash text-white"></i>
                  </span>
                </template>
                <span>ELiminar Producto</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-warning badge-pill pointer float-right sdw mr-3"
                    @click="dialog = true"
                  >
                    <i class="fas fa-edit text-dark"></i>
                  </span>
                </template>
                <span>Editar Producto</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <v-container >
                <!-- 2°second ROW -->
                <v-row>
                  <v-col cols="12" sm="12" md="12" >
                    <!-- 1°first List-->
                    <ul class="list-group mb-3">
                      <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">{{productDetails.name}}</span>
                        <span class="badge badge-secondary badge-pill">ID: {{productDetails.id}}</span>
                      </h4>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">SKU: {{productDetails.sku}}</h6>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fas fa-cubes"></i>
                          </span>
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">EAN: {{productDetails.ean}}</h6>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fa fa-barcode"></i>
                          </span>
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Categoria: {{ProductCategoryName}}</h6>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fas fa-archive"></i>
                          </span>
                        </div>
                      </li>
                      <li class="list-group-item d-flex justify-content-between bg-light">
                        <div class="text-success">
                          <h6 class="my-0">PRECIO</h6>
                        </div>
                        <span class="text-success">
                          <i class="fas fa-arrow-up"></i>
                          CLP$ {{productDetails.price}}
                        </span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between bg-light">
                        <div class="text-warning">
                          <h6 class="my-0">COSTO</h6>
                        </div>
                        <span class="text-warning">
                          <i class="fas fa-arrow-down"></i>
                          CLP$ {{productDetails.cost}}
                        </span>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <!--Dialog--->
                <v-dialog v-model="dialog" width="600" persistent>
                  <v-container>
                    <!-- 3°third ROW -->
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <!-- 2°second Card -->
                        <v-card>
                          <v-card-title>Editar Producto</v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-form ref="form" v-model="valid" lazy-validation>
                              <v-row >
                                <v-col cols="12" sm="6" md="2" v-if="product.id">
                                  <v-text-field
                                    v-model="product.id"
                                    readonly="readonly"
                                    label="ID"
                                    prepend-icon="vpn_key"
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="10">
                                  <v-text-field
                                    v-model="product.name"
                                    label="producto"
                                    prepend-icon="add_business"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6" >
                                  <v-select
                                    v-model="category_id"
                                    :items="categories"
                                    item-text="name"
                                    item-value="id"
                                    label="Categorías"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="product.price" type="number" prefix="$" label=" Precio"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="product.cost" type="number" prefix="$" label="Costo"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="product.sku" label="SKU"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <v-text-field v-model="product.ean" label="EAN"></v-text-field>
                                </v-col>
                              </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="#FFD700"
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="editProduct()"
                            >
                              <v-icon color="#000000" dark>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#FF6347"
                              @click="dialog = false"
                            >
                              <v-icon dark>mdi-close</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-dialog>
                <!-- End Dialog--->
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    boolean: false,
    valid: true,
    dialogLoadBar: false,
    dialog: false,
    category_id: 0,
    productDetails: {},
    ProductCategoryName: '',
    product: {},
    categories: [],
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Productos", route: "/inventario/productos" },
      {
        title: "➁ Detalle Producto",
        route: `/inventario/productos/${this.id}`,
      },
    ]);
    this.fetchProduct();
    this.fetchCategories();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    //this.initialize();
  },
  beforeDestroy(){

    localStorage.removeItem("productDetail");
  },
  methods: {
    initialize() {},
    close() {
      this.dialog = false;
    },
    editProduct() {
      var vm = this;

        // EDIT PRODUCT

        if (!vm.product.name.length || vm.product.name == null) {
          vm.$bvToast.toast("El nombre del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[1].focus();
          return;
        }

        if (
          vm.category_id === 0 ||
          isNaN(vm.category_id)
        ) {
          vm.$bvToast.toast("Seleccione una categoría del producto porfavor", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[2].focus();
          return;
        }

        if (vm.product.price === 0 || isNaN(vm.product.price)) {
          vm.$bvToast.toast("El precio del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[4].focus();
          return;
        }

        if (vm.product.cost === 0 || isNaN(vm.product.cost)) {
          vm.$bvToast.toast("El costo del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[5].focus();
          return;
        }

        if (!vm.product.sku.length || vm.product.sku == null) {
          vm.$bvToast.toast("El SKU del producto no debe ir vacío", {
            title: `Formulario Invalido`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
          vm.$refs.form.$el[6].focus();
          return;
        }

  if (this.$refs.form.validate()) {

          this.axios({
        url: "inventory/products/" + vm.product.id,
        method: "PUT",
        data: {
          sku: vm.product.sku,
          name: vm.product.name,
          cost: vm.product.cost,
          price: vm.product.price,
          ean: vm.product.ean,
          category_id: vm.category_id,
          store_id: 1,
        },
      })
        .then((response) => {
        this.fetchProduct();
        this.$bvToast.toast(
          `El Producto: ${vm.productDetails.name}, ha sido actualizado el ${this.dateMsg} exitosamente!`,
          {
            title: `Información`,
            variant: "success",
            solid: true,
            toaster: "b-toaster-bottom-center",
          }
        );
        })
        .catch((error) => {
         // console.log(error);
        });


  }


      this.close();
    },
    fetchProduct() {
      var vm = this;
      this.axios({
        url: "inventory/products/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          console.log(response.data);
          vm.product = response.data;

          //use localstorage to fix undefined rendering alerts issue
          if(localStorage.getItem("productDetail") !== null){
            localStorage.setItem("productDetail", JSON.stringify(response.data))
            this.productDetails = JSON.parse(localStorage.getItem("productDetail"));
          }else{
            localStorage.removeItem("productDetail");
            localStorage.setItem("productDetail", JSON.stringify(response.data))
            this.productDetails = JSON.parse(localStorage.getItem("productDetail"));
            this.ProductCategoryName = JSON.parse(localStorage.getItem("productDetail")).category.name;
            this.category_id = JSON.parse(localStorage.getItem("productDetail")).category.id
          }
          
        })
        .catch((error) => {
         // console.log(error);
        });
    },
    fetchCategories() {
      var vm = this;
      this.axios({
        url: "inventory/categories",
        method: "GET",
      })
        .then((response) => {
         // console.log(response.data.data);
          vm.categories = response.data.data;
        })
        .catch((error) => {
        //  console.log(error);
        });
    },
    eliminate(){
      var vm = this;

      const confirmacion = confirm(
        `Esta seguro de eliminar eL Producto: ${vm.productDetails.name} || ID: ${vm.product.id}?`
      );

      if (confirmacion) {
        //set to localStorage to persist alert data on the redirected route
        localStorage.setItem(
          "deleteProducto",
          JSON.stringify({
            id: vm.product.id,
            name: vm.productDetails.name,
          })
        );

        this.axios({
          url: "inventory/products/" + vm.product.id,
          method: "DELETE",
        })
          .then((response) => {
            //vm.fetchProduct();

            vm.$bvToast.toast(
              `El Producto: ${vm.productDetails.name} ha sido eliminado, el ${vm.dateMsg} exitosamente!`,
              {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
              }
            );
            //rediect to products
            vm.$router.push("/inventario/productos");
          })
          .catch((error) => {
            //console.log(error);
          });

      }
    }
  },
};
</script>

